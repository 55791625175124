@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-[Raleway];
  }

  li {
    @apply px-4 cursor-pointer;
  }
}

@layer utilities {
  .btn {
    @apply py-2 px-9 border-2 rounded-md
     border-dark_primary rounded-br-3xl font-medium;
  }
  .content-div {
    @apply bg-no-repeat bg-cover bg-center h-64;
  }

  .status-message {
    @apply text-white mb-2 text-center;
  }
  .profile-image {
    @apply rounded-3xl mx-auto;
  }
}
.profile-image {
  width: 100%; /* Responsive: it will scale with the container */
  max-width: 280px; /* Max width for the image */
  height: auto; /* Maintain aspect ratio */
}

@media only screen and (max-width: 500px) {
  .captcha-container {
    transform: scale(0.77);
    transform-origin: center center;
  }
}

@media only screen and (max-width: 280px) {
  .captcha-container {
    transform: scale(0.60);
    transform-origin: 0 0;
  }
}
